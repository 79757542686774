<script setup lang="ts">
const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const route = useRoute();

const shouldRender = computed(() => {
    const currentRegion: Market = route.query.market;
    if (currentRegion == null) return false;
    return props.blok.visibleIn.includes(currentRegion);
});
</script>

<template>
    <StoryblokComponent
        v-for="item in blok.body"
        v-if="shouldRender"
        :key="item._uid"
        :blok="item"
    />
</template>
